<template>
  <El :bgcolor="color" :shadows="!!color">
    <El :max-width="400" mx="auto" p="20px 20px 15px 30px">
      <ion-grid>
        <ion-row class="ion-align-items-center">
          <ion-col size="1">
            <ion-icon @click="back" name="md-arrow-back" class="icon-back cursor-pointer"></ion-icon>
          </ion-col>
          <ion-col class="text-center">
            <El :font-size="15" font-weight="bold">{{ title }}</El>
          </ion-col>
          <ion-col size="1" v-if="showHelp" class="text-right">
            <router-link :to="{ name: helpPage, query: { token: this.token } }">
              <ion-icon name="md-help-circle-outline" class="help icon-blue cursor-pointer"></ion-icon>
            </router-link>
          </ion-col>
        </ion-row>
      </ion-grid>
    </El>
  </El>
</template>

<script>
import { addIcons } from "ionicons";
import { arrowBack, helpCircleOutline } from "ionicons/icons";
import steps from "./steps";

addIcons({
  "md-arrow-back": arrowBack.md,
  "md-help-circle-outline": helpCircleOutline.md
});

export default {
  name: "EnrollmentHeader",
  props: {
    color: String,
    token: String,
    title: String
  },
  data() {
    return {
      helpPage: steps.NEED_HELP
    };
  },
  computed: {
    showHelp() {
      return this.$route.name !== this.helpPage;
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.icon-back {
  font-size: 28px;
  vertical-align: middle;
  margin-left: -4px;
}
.help {
  font-size: 26px;
  vertical-align: middle;
}
</style>
