<template>
  <El h="100%" :bgcolor="backgroundColor" :fontSize="18">
    <Header v-if="showHeader" :title="$copy.headerTitle" :color="headerColor" :token="token" />
    <El class="enrollment" :class="{ 'with-header': showHeader }">
      <El :maxWidth="400" mx="auto" :px="30" :pb="40">
        <router-view v-if="patient || notFound" :api="api" :patient="patient" :token="token" :next="nextPage" v-bind="$attrs" />
      </El>
    </El>
  </El>
</template>

<script>
import Header from "@/components/EnrollmentV2/Header";
import { FIRST_STEP, NOT_FOUND } from "@/components/EnrollmentV2/steps";
import EnrollmentApi from "@/services/EnrollmentApi";

export default {
  name: "Enrollment",
  components: {
    Header
  },
  props: {
    backgroundColor: String,
    headerColor: String,
    next: String,
    showHeader: Boolean,
    token: String
  },
  data() {
    return {
      api: EnrollmentApi,
      loading: undefined
    };
  },
  computed: {
    nextPage() {
      return { name: this.next, query: { token: this.token } };
    },
    patient: {
      get() {
        return this.$store.state.enrollment.patient;
      },
      set(value) {
        this.$store.commit("enrollment/patient", value);
      }
    },
    notFound() {
      return this.$route.name === NOT_FOUND;
    }
  },
  async created() {
    if (this.$route.name === NOT_FOUND || this.patient) {
      return;
    }
    // if no token, reroute to not found
    if (!this.token) {
      this.$router.replace({ name: NOT_FOUND });
      return;
    }
    // if the user refreshes the page, we have to start from the beginning
    if (this.$route.name !== FIRST_STEP) {
      this.$router.replace({ name: FIRST_STEP, query: { token: this.token } });
    }

    this.getEnrollment();
  },
  methods: {
    async getEnrollment() {
      try {
        this.loading = true;
        await this.$store.dispatch("enrollment/getEnrollment", { token: this.token });
      } catch (error) {
        khanSolo.log(error);
        this.$router.replace({ name: NOT_FOUND });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=PT+Mono&display=swap");

.enrollment {
  height: 100%;
  overflow-y: auto;
}
.enrollment.with-header {
  height: calc(100% - 63px);
}
input.form-control.mobile,
select.form-control.mobile {
  font-size: 16px;
  margin-bottom: 20px;
}
select.form-control.mobile {
  height: 43px;
}
</style>
